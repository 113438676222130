import { useEffect, useState } from "react";
import { NavigateOptions, useLocation } from "react-router-dom";
import { useAppContext } from "../../../hooks/useAppContext";
import { IFacility } from "../../../types/IFacility";
import { useStoreSelfNavigation } from "../../../hooks/useStorSelfNavigation";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { kioskFacilityId } from "../../../globalContext";
import { PaymentSecurityLevel } from "../../../types/PaymentSecurityLevel";

const BASE_URL = "/payments";

const navigationPaths = {
    lookup: `${BASE_URL}/lookup`,
    selectUnit: `${BASE_URL}/select-unit`,
    paymentAmount: `${BASE_URL}/payment-amount`,
    paymentMethod: `${BASE_URL}/payment-method`,
    confirmPayment: `${BASE_URL}/confirm-payment`,
    noUnitsFound: `${BASE_URL}/no-units-found`,
    newPaymentMethod: `${BASE_URL}/payment-method/new`,
};

export const usePaymentNavigation = () => {
    const [phoneNumber] = useQueryParam("phoneNumber", StringParam)
    const [ledgerId] = useQueryParam("ledgerId", StringParam)
    const [facilityId] = useQueryParam("facilityId", StringParam)
    const [prePayMonths] = useQueryParam("prePayMonths", NumberParam)

    const navigate = useStoreSelfNavigation();
    const { pathname, search } = useLocation()

    const handleNavigate = (path: string, queryParameters: { [key: string]: any } = {}, options = {}) => {
        const newPath = `${path}?${new URLSearchParams(queryParameters).toString()}`;
        if (newPath !== pathname + search) {
            navigate(newPath, undefined, options);
        }
    };

    return {
        lookup: (options: NavigateOptions = {}) =>
            handleNavigate("/payments/lookup", {}, options),

        selectUnit: (params: { facilityId?: string, phoneNumber: string }, options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.selectUnit, { facilityId, ...params }, options),

        paymentAmount: (params: { facilityId?: string, phoneNumber?: string, ledgerId: string }, options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.paymentAmount, { phoneNumber, facilityId: kioskFacilityId || facilityId, ...params }, options),

        paymentMethod: (options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.paymentMethod, { phoneNumber, ledgerId, prePayMonths, facilityId: kioskFacilityId || facilityId }, options),

        confirmPayment: (params: { paymentMethodId: string, autoPayDay: number }, options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.confirmPayment, { phoneNumber, ledgerId, prePayMonths, ...params }, options),

        newPaymentMethod: (options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.newPaymentMethod, { phoneNumber, ledgerId, prePayMonths }, options),

        noUnitsFound: (params: { phoneNumber?: string } = {}, options: NavigateOptions = {}) =>
            handleNavigate(navigationPaths.noUnitsFound, { phoneNumber, ...params }, options),

        account: (options: NavigateOptions = {}) =>
            handleNavigate("/account", options),
    }
}

export const usePaymentNavigate = () => {
    const { loadingAppContext, phoneNumber, facilityLedgers } = useAppContext();
    const [pending, setPending] = useState(false);
    const navigateTo = usePaymentNavigation();

    const navigateToPayments = () => {
        if (!phoneNumber) {
            // go to page to look up ledgers by phone number
            if (_context.companySettings.paymentSecurityLevel == PaymentSecurityLevel.AuthorizedAccountOnly) {
                navigateTo.account()
            } else {
                navigateTo.lookup()
            }
        } else if (loadingAppContext) {
            // wait until app context is loaded before navigating...
            setPending(true)
        } else if (facilityLedgers.length > 1) {
            navigateTo.selectUnit({ phoneNumber });
            /** ACTIONS on following page **
             * SELECT UNIT and [Continue] or
             * [Change Facility] or
             * [Look up a different account] */
        } else if (facilityLedgers.length === 1) {
            navigateTo.paymentAmount({ phoneNumber, ledgerId: facilityLedgers[0].id })
        } else {
            navigateTo.noUnitsFound({ phoneNumber })
            /** ACTIONS on following page **
             * [Lookup up by phone number] or
             * [Change Facility] */
        }
    }

    useEffect(() => {
        if (pending && !loadingAppContext) {
            navigateToPayments()
        }
    }, [loadingAppContext, pending])

    return {
        pending,
        navigateToPayments
    };
};
